import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo"

import SuccessSection from "../components/SuccessSection"

const Success = () => {

   return (
      <Layout>
         <SEO title="Página de Sucesso"/>
         <SuccessSection 
            title="Obrigado!"
            message="Sua mensagem foi enviada com sucesso!"
            label="Continuar" 
            target="/" />
      </Layout>
   )
}

export default Success